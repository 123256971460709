import React, { useContext, useEffect } from 'react';
import { Button, Form, Row, Col, Input, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { requireFieldFocus } from '../../tools/Tools';
import { ToolsContext } from '../../context/Tools';
import PhoneNumber from '../../components/PhoneNumber';
import FormItemCyrillic from '../../components/FormItemCyrillic';
import { Confirm, CountdownModal, Info } from '../../components/Confirm';
import { MSG, TYPE, USERROLE } from '../../constants/Constant';
import {
  createNewUser,
  getGetOrgAdmin,
  updateUserRequest,
} from '../../service/userService';
import { PATTERN_EMAIL } from '../../constants/Pattern';

function RequestAddUser(props) {
  const toolsStore = useContext(ToolsContext);

  const { selectedRole, selectedOrg, callType, userInfo } = props;
  const [form] = Form.useForm();

  const saveNewUser = values => {
    const saveData = {
      orgId: selectedOrg?.id,
      userRole: { id: selectedRole.id },
      position: values.position,
      phone: values.phone,
      orgName: values.orgName,
    };
    toolsStore.setIsShowLoader(true);
    if (callType === TYPE.NEW) {
      createNewUser(saveData)
        .then(() => {
          /// Хөндлөнгийн аудит эрх дээр админ мэдээлэл харуулахгүй
          if (selectedRole.id === 6) {
            toolsStore.setIsShowLoader(false);
            Info({
              isSuccess: true,
              header: 'Таны хүсэлт амжилттай илгээгдлээ.',
              text: 'Системд нэвтрэх эрх ажлын 3 хоногийн дотор цахим шуудангаар илгээх болно.',
              afterClose: () => {
                props.close(true);
              },
            });
          } else {
            getGetOrgAdmin({ orgId: selectedOrg?.id })
              .then(result => {
                if (result && result.length > 0) {
                  CountdownModal({
                    isSuccess: true,
                    header: 'Таны хүсэлт амжилттай илгээгдлээ.',
                    text: 'Системд нэвтрэх эрхийг доорх эрх бүхий танай байгууллагын админ баталгаажуулах болно.',
                    width: 400,
                    data: result.length > 0 && (
                      <div>
                        {result?.map(el => (
                          <p style={{ fontSize: 15 }}>
                            {el?.lastname?.substring(0, 1)}.{el?.firstname} (
                            {el?.phone})
                          </p>
                        ))}
                      </div>
                    ),
                    afterClose: () => {
                      props.close(true);
                    },
                  });
                } else {
                  Info({
                    isSuccess: true,
                    header: 'Таны хүсэлт амжилттай илгээгдлээ.',
                    text: 'Системд нэвтрэх эрх ажлын 3 хоногийн дотор цахим шуудангаар илгээх болно.',
                    afterClose: () => {
                      props.close(true);
                    },
                  });
                }
              })
              .catch(() =>
                Info({
                  isSuccess: true,
                  header: 'Таны хүсэлт амжилттай илгээгдлээ.',
                  text: 'Системд нэвтрэх эрх ажлын 3 хоногийн дотор цахим шуудангаар илгээх болно.',
                  afterClose: () => {
                    props.close(true);
                  },
                })
              )
              .finally(() => toolsStore.setIsShowLoader(false));
          }
        })
        .catch(() => toolsStore.setIsShowLoader(false));
    } else if (callType === TYPE.EDIT) {
      updateUserRequest(userInfo.id, saveData)
        .then(() => {
          message.success(MSG.SUCCESS);
          toolsStore.setIsShowLoader(false);
          props.close(true);
        })
        .catch(() => {
          toolsStore.setIsShowLoader(false);
        });
    }
  };

  const requestNewUser = () => {
    if (
      selectedRole?.code === USERROLE.subAdmin &&
      selectedOrg?.budgetGovernor !== 1
    ) {
      Info({
        text: 'Тухайн эрхийг зөвхөн Төсвийн ерөнхийлөн захирагч ангилалтай байгууллагууд руу илгээх боломжтой',
      });
    } else {
      form
        .validateFields()
        .then(values => {
          if (callType === TYPE.EDIT) {
            let isChanged = false;
            if (selectedOrg && selectedOrg?.id !== userInfo.orgId) {
              isChanged = true;
            }
            if (values.phone !== userInfo.phone) {
              isChanged = true;
            }
            if (values.position !== userInfo.position) {
              isChanged = true;
            }
            if (!isChanged) {
              message.warning('Өөрчлөгдсөн мэдээлэл байхгүй байна!');
              return;
            }
          }
          Confirm(
            () => saveNewUser(values),
            callType === TYPE.NEW
              ? 'Та хэрэглэгчийн хүсэлт илгээхдээ итгэлтэй байна уу?'
              : 'Та хүсэлтийг засахдаа итгэлтэй байна уу?'
          );
        })
        .catch(() => {
          requireFieldFocus();
        });
    }
  };

  useEffect(() => {
    if (callType === TYPE.EDIT) {
      form.setFieldsValue({
        position: userInfo.position,
        phone: userInfo.phone,
        orgName: userInfo.orgName,
      });
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ email: toolsStore.generalInfo.userInfo.email }}
    >
      <Row gutter={80} align="middle">
        <Col lg={12}>
          <Form.Item
            label="Цахим шуудан"
            name="email"
            rules={[
              !toolsStore.generalInfo.userInfo.email && {
                pattern: PATTERN_EMAIL,
                message: 'И-мэйл буруу байна!',
              },
              {
                required: true,
                message: 'Энэ хэсгийг заавал бөглөнө үү!',
              },
            ]}
          >
            <Input
              placeholder="Цахим шуудан"
              disabled={toolsStore.generalInfo.userInfo.email}
            />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <FormItemCyrillic name="orgName" label="Байгууллагын нэр" required>
            <Input />
          </FormItemCyrillic>
        </Col>
        <Col lg={12}>
          <FormItemCyrillic name="position" label="Албан тушаал" required>
            <Input />
          </FormItemCyrillic>
        </Col>
        <Col lg={12}>
          <PhoneNumber />
        </Col>
        <Col lg={24}>
          <Button
            type="primary"
            size="large"
            onClick={requestNewUser}
            style={{ float: 'right' }}
          >
            Хүсэлт илгээх
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default withRouter(RequestAddUser);
