/* eslint-disable import/no-cycle */
import React, { useContext, useState, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, message, Space, Button } from 'antd';
import OrgContext from '../../../context/OrgContext';
import FileArray from '../../../components/fileUpload/FileArray';
import { MSG, ORG_WISH, TYPE } from '../../../constants/Constant';
import { Confirm } from '../../../components/Confirm';
import Description from '../../../components/description/Description';
import { ToolsContext } from '../../../context/Tools';
import FormItemCyrillic from '../../../components/FormItemCyrillic';
import OrgModal from './OrgModal';
import {
  approveOrganization,
  rejectOrganization,
  requestOrganization,
} from '../../../service/organizationServices';
import { getFiles } from '../../../service/fileServices';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function ChangeInfo(props) {
  const { isShowLoader, setIsShowLoader } = useContext(ToolsContext);
  const org = useContext(OrgContext);
  const {
    callType,
    requestData,
    isModalVisibleChangeInfo,
    close,
    changedData,
  } = props;
  const [form] = Form.useForm();

  const [fileArrayState, setFileArrayState] = useState();
  const [generateUuid, setGenerateUuid] = useState();
  const [isVisibleDescription, setIsVisibleDescription] = useState(false);
  const [isModalVisibleOrg, setIsModalVisibleOrg] = useState(false);

  const isDisabled = callType === TYPE.VIEW || callType === TYPE.DECIDE;

  const save = () => {
    form.validateFields().then(values => {
      // if (!generateUuid) {
      //   message.warn('Нотлох баримт хавсаргана уу!');
      //   return;
      // }
      setIsShowLoader(true);
      const saveData = {
        ...changedData,
        ...values,
        decisionUuid: generateUuid,
        wish: ORG_WISH.CHANGE_INFO,
      };

      requestOrganization(org.previousInfo.id, saveData)
        .then(() => {
          message.success(MSG.SUCCESS_REQUEST);
          setIsShowLoader(false);
          close(true);
        })
        .catch(() => {
          setIsShowLoader(false);
        });
    });
  };

  const reject = description => {
    setIsShowLoader(true);
    const saveData = { rejectedDescription: description };

    rejectOrganization(requestData.id, saveData)
      .then(() => {
        message.success(MSG.SUCCESS_REQUEST);
        setIsVisibleDescription(false);
        close(true);
      })
      .finally(() => {
        setIsShowLoader(false);
      });
  };

  const approve = () => {
    setIsShowLoader(true);

    if (requestData.originOrg) {
      delete requestData.originOrg;
    }

    approveOrganization(requestData.id, requestData)
      .then(() => {
        message.success(MSG.SUCCESS_REQUEST);
        close(true);
      })
      .finally(() => {
        setIsShowLoader(false);
      });
  };

  const calcOrgData = () => {
    const originOrg = requestData?.originOrg || {};
    const changedFields = requestData.rejectedFields
      ? requestData.rejectedFields.split('&')
      : [];

    const orgData = {
      ...requestData,
      uuid: originOrg?.uuid,
      orgContacts: originOrg?.orgContacts,
      changedFields,
    };
    return orgData;
  };

  useEffect(() => {
    if (requestData) {
      form.setFieldsValue({ ...requestData });
      if (requestData.decisionUuid) {
        const params = {
          search: {
            status: true,
            parentUuid: requestData.decisionUuid,
          },
        };
        getFiles(params).then(result => {
          const files = result.content || [];
          files.forEach((file, index) => {
            file.key = index;
          });
          setFileArrayState(files);
        });
      } else setFileArrayState([]);
    } else {
      setFileArrayState([{ key: 0 }]);
    }
  }, []);

  return (
    <div>
      <Modal
        title="Баталгаажсан мэдээлэлд өөрчлөлт оруулах"
        visible={isModalVisibleChangeInfo}
        onCancel={() => close(false)}
        footer={false}
        width="45%"
        height="60%"
      >
        <Form
          form={form}
          {...layout}
          name="orgRequest"
          initialValues={{ remember: true }}
          labelAlign="left"
        >
          <Form.Item
            label="Шийдвэрийн дугаар:"
            name="decisionNumber"
            rules={[
              { required: true, message: 'Энэ хэсгийг заавал бөглөнө үү!' },
            ]}
          >
            <Input min="0" max="20" disabled={isDisabled} />
          </Form.Item>
          <Form.Item
            label="Шийдвэрийн огноо:"
            name="decisionAt"
            rules={[
              { required: true, message: 'Энэ хэсгийг заавал бөглөнө үү!' },
            ]}
          >
            {isDisabled ? (
              <Input disabled={isDisabled} />
            ) : (
              <DatePicker placeholder="Огноо сонгох" disabled={isDisabled} />
            )}
          </Form.Item>
          <FormItemCyrillic
            label="Шийдвэрийн шалтгаан:"
            name="decisionDescription"
          >
            <TextArea showCount maxLength={100} disabled={isDisabled} />
          </FormItemCyrillic>

          <Button type="secondary" onClick={() => setIsModalVisibleOrg(true)}>
            Маягт дуудах
          </Button>

          <p className="require">Нотлох тушаал, шийдвэр хавсаргах:</p>
          {React.cloneElement(<FileArray />, {
            isView: isDisabled,
            fileArrayState,
            setFileArrayState,
            generateUuid,
            setGenerateUuid,
          })}
          <div className="modal-footer-btn">
            <Space>
              {callType === TYPE.NEW && (
                <>
                  <Button
                    size="middle"
                    type="secondary"
                    disabled={isShowLoader}
                    onClick={close}
                  >
                    Болих
                  </Button>
                  <Button
                    size="middle"
                    type="primary"
                    htmlType="button"
                    disabled={isShowLoader}
                    onClick={save}
                  >
                    Хүсэлт илгээх
                  </Button>
                </>
              )}
              {callType === TYPE.DECIDE && (
                <>
                  <Button
                    danger
                    size="middle"
                    htmlType="button"
                    disabled={isShowLoader}
                    onClick={() => setIsVisibleDescription(true)}
                  >
                    Татгалзах
                  </Button>
                  <Button
                    type="primary"
                    size="middle"
                    htmlType="button"
                    disabled={isShowLoader}
                    onClick={() =>
                      Confirm(
                        approve,
                        'Та уг хүсэлтийг зөвшөөрөхдөө итгэлтэй байна уу?'
                      )
                    }
                  >
                    Зөвшөөрөх
                  </Button>
                  {isVisibleDescription && (
                    <Description
                      title="Та уг хүсэлтийг татгалзахдаа итгэлтэй байна уу?"
                      visible={isVisibleDescription}
                      setVisible={setIsVisibleDescription}
                      save={desc => reject(desc)}
                    />
                  )}
                </>
              )}
            </Space>
          </div>
          {isModalVisibleOrg && (
            <OrgModal
              orgCallType={TYPE.VIEW}
              orgData={calcOrgData()}
              isModalVisibleOrg={isModalVisibleOrg}
              close={() => setIsModalVisibleOrg(false)}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
}
